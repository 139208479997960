import { ApolloError, useMutation } from "@apollo/client";
import {
  LoadCancelsDocument,
  LoadCancelsMutation,
  LoadCancelsMutationVariables,
} from "gql/__generated__/graphql";
import reloadImage from "images/reload.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./LoadCancelsModal.module.scss";
import { ApolloContext } from "components/provider/apollo/context";
import { useMediaQueryContext } from "components/provider/MediaQueryProvider";
import {
  getExtensionsCode,
  invalidIdOrPasswordErrorCodes,
} from "domain/graphql";
import LoadingIcon from "components/ui/LoadingIcon/LoadingIcon";

type UseLoadCancelsModalReturn = {
  renderModal: () => JSX.Element;
  startLoadCancels: () => void;
};

enum LoadCancelsModalState {
  NOT_OPENED = "NOT_OPENED",
  LOADING = "LOADING",
  LOADED = "LOADED",
  ID_OR_PASSWORD_ERROR = "ID_OR_PASSWORD_ERROR",
  GENERAL_ERROR = "GENERAL_ERROR",
}

const isInvalidIdOrPasswordError = (error: ApolloError): boolean => {
  const graphqlErrors = error.graphQLErrors;
  return graphqlErrors.some((graphqlError) => {
    const code = getExtensionsCode(graphqlError);
    if (!code) {
      return false;
    }
    return invalidIdOrPasswordErrorCodes.includes(code);
  });
};

export const useLoadCancelsModal = (
  onSuccess: () => void
): UseLoadCancelsModalReturn => {
  const [state, setState] = useState<LoadCancelsModalState>(
    LoadCancelsModalState.NOT_OPENED
  );
  const { lapsedSec, startCounting, stopCounting } = useLapsedSec();

  const [loadCancels, { loading, data, error }] = useMutation<
    LoadCancelsMutation,
    LoadCancelsMutationVariables,
    ApolloContext
  >(LoadCancelsDocument);

  useEffect(() => {
    // ちょっと分かりにくいのでrefactorして良さそう
    if (loading) {
      setState(LoadCancelsModalState.LOADING);
    } else if (state === LoadCancelsModalState.NOT_OPENED) {
      return;
    } else if (data?.loadCancels.ok) {
      setState(LoadCancelsModalState.LOADED);
      onSuccess();
      stopCounting();
    } else {
      if (error && isInvalidIdOrPasswordError(error)) {
        setState(LoadCancelsModalState.ID_OR_PASSWORD_ERROR);
      } else {
        setState(LoadCancelsModalState.GENERAL_ERROR);
      }
      stopCounting();
    }
  }, [loading, data, state, onSuccess, stopCounting, error]);

  const closeModal = useCallback(() => {
    setState(LoadCancelsModalState.NOT_OPENED);
    stopCounting();
  }, [stopCounting]);

  const startLoadCancels = useCallback(() => {
    if (state === LoadCancelsModalState.LOADING) {
      // idempotency check
      return;
    }

    startCounting();

    loadCancels({
      variables: {
        input: {
          mode: "NORMAL",
        },
      },
      context: {
        disableErrorDialog: true,
      },
    });
  }, [loadCancels, state, startCounting]);

  const renderModal = useCallback(() => {
    return (
      <ModalView
        state={state}
        lapsedSec={lapsedSec}
        closeModal={closeModal}
        restartLoadCancels={startLoadCancels}
        errorMessage={error?.message}
      ></ModalView>
    );
  }, [state, closeModal, startLoadCancels, lapsedSec, error]);

  return {
    startLoadCancels,
    renderModal,
  };
};

const useLapsedSec = () => {
  const [lapsedSec, setLapsedSec] = useState<number>(0);
  const [intervalID, setIntervalID] = useState<NodeJS.Timeout | null>(null);

  const startCounting = useCallback(() => {
    setLapsedSec(0);
    const _intervalID = setInterval(() => {
      setLapsedSec((old) => old + 1);
    }, 1000);
    setIntervalID(_intervalID);
  }, []);

  const stopCounting = useCallback(() => {
    if (intervalID) {
      clearInterval(intervalID);
      setIntervalID(null);
    }
  }, [intervalID]);

  return {
    lapsedSec,
    startCounting,
    stopCounting,
  };
};

type ModalProps = {
  state: LoadCancelsModalState;
  closeModal: () => void;
  restartLoadCancels: () => void;
  lapsedSec: number;
  errorMessage?: string;
};

const loadedMessages = ["予約管理サービスと同期が完了しました。"];
const generalErrorMessages = [
  "予約管理サービスとの同期に失敗しました。",
  "しばらくしてから再度お試しください。",
];

const ModalView = ({
  state,
  closeModal,
  restartLoadCancels,
  lapsedSec,
  errorMessage,
}: ModalProps) => {
  const { isMobile } = useMediaQueryContext();
  const loadingMessages = useMemo(() => {
    const messages = [
      "予約管理サービスと同期中です。",
      "5~25秒ほどお待ちください。",
      lapsedSec + "秒経過",
    ];

    if (isMobile) {
      return messages;
    }
    return [messages[0] + messages[1], messages[2]];
  }, [lapsedSec, isMobile]);

  if (state === LoadCancelsModalState.NOT_OPENED) {
    return <div></div>;
  }

  let messages = loadingMessages;
  if (state === "LOADED") {
    messages = loadedMessages;
  } else if (state === "GENERAL_ERROR") {
    messages = generalErrorMessages;
  } else if (state === "ID_OR_PASSWORD_ERROR") {
    messages = errorMessage ? [errorMessage] : [""];
  }

  const showRestartButton =
    state === "ID_OR_PASSWORD_ERROR" || state === "GENERAL_ERROR";
  const showButtons =
    state === "LOADED" ||
    state === "ID_OR_PASSWORD_ERROR" ||
    state === "GENERAL_ERROR";

  return (
    <div className={styles.modal}>
      <div
        className={styles.overlay}
        onClick={() => {
          if (
            state === "LOADED" ||
            state === "ID_OR_PASSWORD_ERROR" ||
            state === "GENERAL_ERROR"
          ) {
            closeModal();
          }
        }}
      ></div>

      <div className={styles.content}>
        <div className={styles.loading_icon_container}>
          {state === LoadCancelsModalState.LOADING && <LoadingIcon />}
        </div>

        {messages.map((message) => (
          <div>{message}</div>
        ))}
        {state === "ID_OR_PASSWORD_ERROR" && (
          <div>
            <div>
              <a
                href="https://forms.gle/GcB2vT9q4T7VSQfs5"
                target="_blank"
                rel="noreferrer"
              >
                ログイン情報フォーム
              </a>
              からID・パスワードを再設定してください。
            </div>
            <div>※変更は1営業日以内に反映されます。</div>
          </div>
        )}

        {showButtons && (
          <div className={styles.buttons_container}>
            <button
              style={{ visibility: showRestartButton ? "visible" : "hidden" }}
              className={styles.restart}
              onClick={restartLoadCancels}
            >
              もう一度試す
            </button>
            <button onClick={closeModal}>閉じる</button>
          </div>
        )}
      </div>
    </div>
  );
};
