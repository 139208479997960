import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useCallback, useMemo } from "react";
import { useLoading } from "../loading/useLoading";
import { createApolloFetch } from "./customFetch/createApolloFetch";
import { getAppAlertMessage } from "domain/graphql";

// createApolloFetchに統合していいかも
const errorLink = onError((error) => {
  // TODO: もっと真面目にエラーを表示する。エラーダイアログを作る。
  const { networkError, graphQLErrors, operation, response } = error;
  const disableErrorDialog = operation.getContext().disableErrorDialog;

  if (networkError || graphQLErrors) {
    console.log("res", response);
    console.log("networkError", networkError);
    console.log("graphQLErrors", graphQLErrors);
    console.log(networkError?.message);
    console.log(networkError?.cause);
    console.log(networkError?.stack);
    console.log(networkError?.name);

    if (!disableErrorDialog) {
      if (graphQLErrors) {
        const appAlertMessage = getAppAlertMessage(graphQLErrors[0]);
        if (appAlertMessage) {
          alert(appAlertMessage);
          return;
        }
      }
      alert("エラーが発生しました。しばらく経ってから再度お試しください。");
    }
  }
});

const useApolloClient = (
  path: string,
  currentSalonID?: number,
  currentUserID?: string
) => {
  const { startLoading } = useLoading();
  const onRequestStart = useCallback(
    (skipLoading: boolean) => {
      if (skipLoading) {
        return () => {};
      }
      return startLoading();
    },
    [startLoading]
  );

  // これがreinitializeされると、実行中のrequestがキャンセルされてしまうので(多分)、
  // むやみにこのコンポーネントが最レンダリングされないように注意。
  // currentSalonIDをstate管理すると、setCurrentSalonIDの時、変にreinitializeされるのでそのような構成にはしてない。
  const apolloClient = useMemo(() => {
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_API_URL + path,
      fetch: createApolloFetch({
        currentSalonID,
        currentUserID,
        onRequestStart,
      }),
    });

    return new ApolloClient({
      link: from([errorLink, httpLink]),
      cache: new InMemoryCache(), // single source of truth
    });
  }, [startLoading, currentSalonID, currentUserID, path]);

  return apolloClient;
};

const Apollo = ({
  currentSalonID,
  currentUserID,
  children,
}: {
  currentSalonID: number;
  currentUserID: string;
  children: React.ReactNode;
}) => {
  const apolloClient = useApolloClient(
    "/graphql",
    currentSalonID,
    currentUserID
  );
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export const PublicApollo = ({ children }: { children: React.ReactNode }) => {
  const apolloClient = useApolloClient("/public_graphql");
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default Apollo;
