import reloadImage from "images/reload.svg";
import styles from "./LoadingIcon.module.scss";

type Props = {
  width?: number;
};

const LoadingIcon = (props: Props) => {
  const width = props.width ?? 70;
  return (
    <img
      src={reloadImage}
      alt="sync"
      style={{ width: width }}
      className={styles.loading_icon}
    />
  );
};

export default LoadingIcon;
