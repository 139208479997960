import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { parse } from "date-fns";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Payment from "./pages/payment/Payment";
import PaymentComplete from "./pages/payment-complete/PaymentComplete";
import Coupon from "./pages/coupon/Coupon";
import CouponMasters from "./pages/coupon_masters";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import amplifyConfig from "./components/provider/aws-exports";
import NotFoundPage from "pages/notFound";
import ForgotPassword from "pages/forgot_password/ForgotPassword";
import Layout from "components/provider/layout/Layout";
import { MediaQueryProvider } from "components/provider/MediaQueryProvider";
import useLogin from "components/provider/auth/useLogin";
import { ReactNode } from "react";
import CouponMasterNew from "pages/coupon_masters/new/new";
import ClaimRule from "pages/claim_rule";
import Apollo, { PublicApollo } from "components/provider/apollo/Apollo";
import {
  LoadingProvider,
  useLoading,
} from "components/provider/loading/useLoading";
import { SalonProvider } from "components/provider/auth/useSalon";
import NewCancel from "pages/new_cancel";
import { isInMaintenance } from "utils/env/maintenance";
import Maintenance from "pages/maintenance/Maintenance";
import SalonSettings from "pages/salon_settings";
import SalonUserInvitationWithoutLoginData from "pages/salon_user_invitation/SalonUserInvitationWithoutLoginData";
import SalonUserInvitationWithLoginData from "pages/salon_user_invitation/SalonUserInvitationWithLoginData";
import ReservationPageRedirect from "pages/reservation_page_redirect/ReservationPageRedirect";
import Rereservation from "pages/rereservation/Rereservation";

Amplify.configure(amplifyConfig);

const PrivateProvider = ({ children }: { children: ReactNode }) => {
  return (
    <MediaQueryProvider>
      <Authenticator>
        <SalonProvider>
          {({ currentSalonID, currentUserID }) => (
            <Apollo
              currentSalonID={currentSalonID}
              currentUserID={currentUserID}
            >
              <Layout>{children}</Layout>
            </Apollo>
          )}
        </SalonProvider>
      </Authenticator>
    </MediaQueryProvider>
  );
};

const PublicProvider = ({ children }: { children: ReactNode }) => {
  return <PublicApollo>{children}</PublicApollo>;
};

function RouterProvider() {
  const { startLoading } = useLoading();
  const { loginData, loading } = useLogin(startLoading);

  return (
    <Router>
      <Routes>
        {loginData ? (
          <>
            <Route
              path="/salon_user_invitation/:secret_value"
              element={
                <PrivateProvider>
                  <SalonUserInvitationWithLoginData
                    currentSalonID={loginData.currentSalonID}
                    currentUserID={loginData.currentUserID}
                  />
                </PrivateProvider>
              }
            />
            <Route
              path="/"
              element={
                <PrivateProvider>
                  <Home />
                </PrivateProvider>
              }
            />
            <Route
              path="coupon_masters"
              element={
                <PrivateProvider>
                  <CouponMasters />
                </PrivateProvider>
              }
            />
            <Route
              path="coupon_masters/new"
              element={
                <PrivateProvider>
                  <CouponMasterNew />
                </PrivateProvider>
              }
            />
            <Route
              path="claim_rule"
              element={
                <PrivateProvider>
                  <ClaimRule />
                </PrivateProvider>
              }
            />
            <Route
              path="salon_settings"
              element={
                <PrivateProvider>
                  <SalonSettings />
                </PrivateProvider>
              }
            />
            <Route
              path="new_cancel"
              element={
                <PrivateProvider>
                  <NewCancel />
                </PrivateProvider>
              }
            />
          </>
        ) : (
          <>
            {loading ? (
              <Route
                path="salon_user_invitation/:secret_value"
                element={<div></div>}
              />
            ) : (
              <Route
                path="salon_user_invitation/:secret_value"
                element={<SalonUserInvitationWithoutLoginData />}
              />
            )}
            <Route path="*" element={<Login />} />
          </>
        )}
        <Route path="forgot_password" element={<ForgotPassword />} />
        <Route path="payment/:payment_id" element={<Payment />} />
        <Route
          path="payment/complete/:payment_id"
          element={<PaymentComplete />}
        />
        <Route path="coupon/:coupon_id" element={<Coupon />} />
        <Route path="notFound" element={<NotFoundPage />} />
        <Route
          path="reservation_page_redirect/cancel/:cancel_id"
          element={<ReservationPageRedirect />}
        />
        <Route
          path="rereservation/cancel/:cancel_id"
          element={
            <PublicProvider>
              <Rereservation />
            </PublicProvider>
          }
        />
      </Routes>
    </Router>
  );
}

function App() {
  if (isInMaintenance) {
    return <Maintenance />;
  }

  return (
    <LoadingProvider>
      <RouterProvider />
    </LoadingProvider>
  );
}

export default App;
