import { useApolloClient, useQuery } from "@apollo/client";
import kokuaImage from "images/kokua.svg";
import styles from "./RereservationPage.module.scss";
import ReservableSlots from "./ReservableSlots/ReservableSlots";
import {
  DateReservableSlotsFragmentDoc,
  GetPreviousReservationDocument,
  GetPreviousReservationQuery,
  GetPreviousReservationQueryVariables,
  GetReservableSlotsChunksDocument,
  GetReservableSlotsChunksQuery,
  GetReservableSlotsChunksQueryVariables,
  ReservableSlotsWithStaffChunkFragmentDoc,
  Staff,
  StaffFragment,
  StaffFragmentDoc,
} from "gql/public/__generated__/graphql";
import { getFragmentData } from "gql/__generated__";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { formatDateUntilMinute } from "utils/date";

const getNoMenuText = (durationMinuteForEmptyMenu: number) => {
  return `メニュー選択なし (${durationMinuteForEmptyMenu}分)`;
};
const getMenuName = (
  names: string[],
  durationMinute?: number,
  durationMinuteForEmptyMenu?: number,
  price?: number
) => {
  if (names.length === 0) {
    return getNoMenuText(durationMinuteForEmptyMenu ?? 0);
  }
  return `【前回ご予約】${names.join(" / ")} (${durationMinute}分) - ¥${price}`;
};
const noStaffID = "no_staff";

// FIXME: 指名スタッフなしを許容するかどうかの分岐
const RereservationPage = () => {
  // TODO: isReservedを確認

  const cancelID = useParams().cancel_id;
  const apolloClient = useApolloClient();

  /* states */
  const [selectedStaffID, setSelectedStaffID] = useState<string | null>(null);
  const [isMenuSelected, setIsMenuSelected] = useState<boolean>(false);
  const [previousReservationData, setPreviousReservationData] =
    useState<GetPreviousReservationQuery | null>(null);
  const { loading, data: reservableSlotsChunksData } = useQuery<
    GetReservableSlotsChunksQuery,
    GetReservableSlotsChunksQueryVariables
  >(GetReservableSlotsChunksDocument, {
    fetchPolicy: "no-cache",
    context: {
      fetchOptions: {
        skipLoading: true, // falseだと、state更新タイミングの関係で、再予約日時取得中もこいつ分のloadingが表示されてしまう?
      },
    },
  });
  const chunks = useMemo(() => {
    return (
      reservableSlotsChunksData?.reservableSlotsChunks.chunks.map((chunk) =>
        getFragmentData(DateReservableSlotsFragmentDoc, chunk)
      ) ?? []
    );
  }, [reservableSlotsChunksData]);
  const selectedMenuDurationMinute = useMemo((): number => {
    if (isMenuSelected) {
      return (
        previousReservationData?.previousReservation.previousReservation
          .menusWithCoupons?.durationMinute ?? 90000
      );
    }
    return (
      previousReservationData?.previousReservation.durationMinuteForEmptyMenu ??
      90000
    );
  }, [previousReservationData, isMenuSelected]);
  const otherStaffSelectedThanPreviousReservation = useMemo(() => {
    const previousStaff =
      previousReservationData?.previousReservation.previousReservation.staff;
    const previousStaffData = getFragmentData(StaffFragmentDoc, previousStaff);
    return previousStaffData && previousStaffData.id !== selectedStaffID;
  }, [previousReservationData, selectedStaffID]);
  const canReservePreviousReservationMenusSet = useMemo(() => {
    return (
      (previousReservationData?.previousReservation
        .canReserveCanceledReservationMenusSet ??
        false) &&
      !otherStaffSelectedThanPreviousReservation
    );
  }, [previousReservationData, otherStaffSelectedThanPreviousReservation]);

  useEffect(() => {
    if (!cancelID) return;
    apolloClient
      .query<GetPreviousReservationQuery, GetPreviousReservationQueryVariables>(
        {
          query: GetPreviousReservationDocument,
          variables: {
            param: {
              cancelId: cancelID,
            },
          },
          fetchPolicy: "no-cache",
          context: {
            fetchOptions: {
              skipLoading: true,
            },
          },
        }
      )
      .then((res) => {
        const previousReservation =
          res.data.previousReservation.previousReservation;
        setPreviousReservationData(res.data);
        setIsMenuSelected(
          res.data.previousReservation.canReserveCanceledReservationMenusSet
        );

        // 前回のスタッフが、現在利用可能(選択肢にある)なら選択する
        let isPreviousStaffAvailable = false;
        const previousStaff = previousReservation?.staff;
        if (previousStaff) {
          const previousStaffData = getFragmentData(
            StaffFragmentDoc,
            previousStaff
          );
          const available = res.data.previousReservation.availableStaffs.find(
            (staff) => {
              const staffData = getFragmentData(StaffFragmentDoc, staff);
              if (staffData.id === previousStaffData.id) {
                return true;
              }
              return false;
            }
          );
          if (available) {
            isPreviousStaffAvailable = true;
          }
          if (isPreviousStaffAvailable) {
            setSelectedStaffID(previousStaffData.id);
          }
        }
      });
  }, [cancelID, apolloClient, setPreviousReservationData]);

  useEffect(() => {
    if (!canReservePreviousReservationMenusSet) {
      setIsMenuSelected(false);
    }
  }, [canReservePreviousReservationMenusSet]);

  const cancelConditionsTexts =
    (isMenuSelected
      ? previousReservationData?.previousReservation.cancelConditionTextsForMenu
      : previousReservationData?.previousReservation
          .cancelConditionTextsForNoMenu) ?? [];

  const menuText = getMenuName(
    previousReservationData?.previousReservation.previousReservation
      .menusWithCoupons?.names ?? [],
    previousReservationData?.previousReservation.previousReservation
      .menusWithCoupons?.durationMinute,
    previousReservationData?.previousReservation.durationMinuteForEmptyMenu ??
      undefined,
    previousReservationData?.previousReservation.previousReservation
      .menusWithCoupons?.price
  );
  const noMenuText = getNoMenuText(
    previousReservationData?.previousReservation.durationMinuteForEmptyMenu ?? 0
  );
  const onSelect = useCallback(
    (startTime: Date) => {
      let message = "以下の内容で予約します。よろしいですか？\n\n";
      message += `予約日時: ${formatDateUntilMinute(startTime)}\n`;

      if (selectedStaffID) {
        const selectedStaff =
          previousReservationData?.previousReservation?.availableStaffs.find(
            (staff) => {
              const staffData = getFragmentData(StaffFragmentDoc, staff);
              return staffData.id === selectedStaffID;
            }
          );
        if (!selectedStaff) {
          alert("スタッフが見つかりませんでした");
          return;
        }

        message += `指名スタッフ: ${
          getFragmentData(StaffFragmentDoc, selectedStaff).name
        }\n`;
      } else {
        message += `指名スタッフ: 未指定\n`;
      }

      if (isMenuSelected) {
        message += `メニュー: ${menuText}\n`;
      } else {
        message += `メニュー: ${noMenuText}\n`;
      }

      if (!window.confirm(message)) {
        return;
      }
    },
    [
      menuText,
      noMenuText,
      isMenuSelected,
      selectedStaffID,
      previousReservationData,
    ]
  );

  return (
    <div className={styles.rereservation_page}>
      <img src={kokuaImage} alt="kokua" style={{ width: "80px" }} />
      <div className={styles.header}>ワンクリック再予約フォーム</div>
      <div className={styles.customer_name_and_salon_name}>
        <div className={styles.customer_name}>
          {"お客様名　" +
            (previousReservationData?.previousReservation.previousReservation
              .customer.name ?? "") +
            "　様"}
        </div>
        <div className={styles.salon_name}>
          {"ご予約店舗名　" +
            (previousReservationData?.previousReservation.salonName ?? "")}
        </div>
      </div>

      <div className={styles.selector}>
        <div className={styles.attribute}>
          <div className={styles.attribute_header}>■ご予約メニュー（税込）</div>
          <select
            value={isMenuSelected ? "true" : "false"}
            onChange={() => setIsMenuSelected(!isMenuSelected)}
          >
            {canReservePreviousReservationMenusSet && (
              <option value="true">{menuText}</option>
            )}
            <option value="false">{noMenuText}</option>
          </select>
        </div>

        <div className={styles.attribute}>
          <div className={styles.attribute_header}>■指名スタッフ</div>
          <select
            value={selectedStaffID ?? noStaffID}
            onChange={(e) => {
              // @ts-ignore
              const value = e.target.value;
              if (value === noStaffID) {
                setSelectedStaffID(null);
                return;
              }
              setSelectedStaffID(value);
            }}
          >
            <option value={noStaffID}>スタッフ選択なし</option>,
            {previousReservationData?.previousReservation.availableStaffs.map(
              (staff) => {
                const staffData = getFragmentData(StaffFragmentDoc, staff);
                return <option value={staffData.id}>{staffData.name}</option>;
              }
            )}
          </select>
        </div>

        <p className={styles.menu_and_staff_caution}>
          「予約メニュー」の指定は、前回ご予約の「指名スタッフ」の場合のみ可能です
        </p>

        <div className={styles.cancel_policy_header}>
          <div className={styles.title}>キャンセルポリシー</div>
          <div className={styles.caution}>
            ※再予約をすると承諾したとみなします
          </div>
        </div>
        <div className={styles.cancel_policies}>
          {cancelConditionsTexts.map((cancelPolicy) => (
            <div className={styles.cancel_policy_content}>{cancelPolicy}</div>
          ))}
        </div>
      </div>

      <div className={styles.slots_wrapper}>
        <ReservableSlots
          loading={loading}
          chunks={chunks}
          durationMinute={selectedMenuDurationMinute}
          today={new Date()}
          currentStaffID={selectedStaffID}
          // TODO:
          onSelectSlot={onSelect}
        />
      </div>
    </div>
  );
};

export default RereservationPage;
