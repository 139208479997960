import LoadingIcon from "components/ui/LoadingIcon/LoadingIcon";
import styles from "./LoadingOverlay.module.scss";

const LoadingOverlay = ({ show }: { show: boolean }) => {
  if (show) {
    return (
      <div className={styles.loading_overlay}>
        <div className={styles.container}>
          <div className={styles.icon}>
            <LoadingIcon />
          </div>
          <div className={styles.message}>予約可能日時を取得中です...</div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default LoadingOverlay;
