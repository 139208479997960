import {
  GetReservableSlotsChunksQuery,
  Hour,
  Minute,
  DateReservableSlotsFragment,
  ReservableSlotsWithStaffChunkFragmentDoc,
} from "gql/public/__generated__/graphql";
import styles from "./ReservableSlots.module.scss";
import { useEffect, useState } from "react";
import {
  ReservableChecker,
  SevenDaysReservableSlotsChunks,
} from "./ReservableSlots.logic";
import { getFragmentData } from "gql/__generated__";
import { addDate } from "utils/date";
import LoadingOverlay from "./LoadingOverlay/LoadingOverlay";

type Props = {
  loading: boolean;
  chunks: DateReservableSlotsFragment[];
  currentStaffID: string | null;
  today: Date;
  durationMinute: number;
  onSelectSlot: (startTime: Date) => void;
};

const getMMDD = (date: Date) => {
  return `${date.getMonth() + 1}/${date.getDate()}`;
};

const getWeekDay = (date: Date) => {
  const weekDay = ["日", "月", "火", "水", "木", "金", "土"][date.getDay()];
  return "（" + weekDay + "）";
};

const isSaturday = (date: Date) => {
  return date.getDay() === 6;
};

const isSunday = (date: Date) => {
  return date.getDay() === 0;
};

const getDayColor = (date: Date) => {
  if (isSaturday(date)) {
    return "blue";
  } else if (isSunday(date)) {
    return "red";
  } else {
    return "black";
  }
};

const ReservableSlots = ({
  loading,
  chunks,
  today,
  currentStaffID,
  durationMinute,
  onSelectSlot,
}: Props) => {
  const [
    sevenDateAndReservableSlotsChunks,
    setSevenDateAndReservableSlotsChunks,
  ] = useState<SevenDaysReservableSlotsChunks>(
    new SevenDaysReservableSlotsChunks(today, chunks)
  );

  useEffect(() => {
    setSevenDateAndReservableSlotsChunks(
      new SevenDaysReservableSlotsChunks(today, chunks)
    );
  }, [today, chunks]);

  const sevenDays = sevenDateAndReservableSlotsChunks.sevenDaysChunks.map(
    ({ date }) => date
  );

  const nextSevenDays = () => {
    const nextFirstDate = addDate(sevenDays[6], 1);
    setSevenDateAndReservableSlotsChunks(
      new SevenDaysReservableSlotsChunks(nextFirstDate, chunks)
    );
  };
  const prevSevenDays = () => {
    const prevFirstDate = addDate(sevenDays[0], -7);
    setSevenDateAndReservableSlotsChunks(
      new SevenDaysReservableSlotsChunks(prevFirstDate, chunks)
    );
  };

  return (
    <div className={styles.reservable_slots}>
      <LoadingOverlay show={loading} />
      <div className={styles.header}>再予約日時を選択 （クリック）</div>
      <div className={styles.week_selector}>
        <button className={styles.selector} onClick={prevSevenDays}>
          ◀ 前の週
        </button>
        <button className={styles.selector} onClick={nextSevenDays}>
          次の週 ▶
        </button>
      </div>

      <div className={styles.table_container}>
        <table className={styles.reservable_slots_table}>
          <thead>
            <th></th>
            {sevenDays.map((date) => {
              return (
                <th key={date.toString()} style={{ color: getDayColor(date) }}>
                  <div>{getMMDD(date)}</div>
                  <div>{getWeekDay(date)}</div>
                </th>
              );
            })}
          </thead>
          <tbody>
            {sevenDateAndReservableSlotsChunks
              .getStartTimes()
              .map((startTime, i) => {
                return (
                  <tr key={i}>
                    <td>{startTime.toString()}</td>
                    {sevenDays.map((date, j) => {
                      const slots =
                        sevenDateAndReservableSlotsChunks.getSlotsByDateAndStaff(
                          date,
                          currentStaffID
                        );
                      const checker = new ReservableChecker(slots);

                      return (
                        <td>
                          {checker.isReservable(startTime, durationMinute) ? (
                            <button
                              className={styles.reservable_slot}
                              onClick={() => {
                                const selectedTime = new Date(date);
                                selectedTime.setHours(
                                  startTime.hour,
                                  startTime.minute,
                                  0,
                                  0
                                );
                                onSelectSlot(selectedTime);
                              }}
                            >
                              ️◎
                            </button>
                          ) : (
                            <div className={styles.not_reservable_slot}>✗</div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReservableSlots;
